export default class Menu {

    constructor( element ) {
        this.element = element;
        this.menu = document.querySelector('[data-menu]');
        this.addEventListeners();
    }

    addEventListeners() {
        this.element.addEventListener('click', (event) => {
            this.toggleMenu();
        })
    }

    toggleMenu() {
        this.element.classList.toggle('open');
        this.menu.classList.toggle('visible');
    }


}