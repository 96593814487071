import Swiper, { Autoplay } from 'swiper';

class Gallery {

    constructor() {
        this.element;
        this.breakpoint = window.matchMedia("(max-width: 767px)")
    }

    init( element ) {

        this.element = element;
        this.gswiper;

        this.initGallery();

    }

    initGallery() {
        this.gswiper = new Swiper( '[data-block-slider]', {
            modules: [ Autoplay ],
            direction: 'horizontal',
            slidesPerView: 1,
            releaseOnEdges: true,
            centeredSlides: true,
            loop: true,
            speed: 5000,
            autoplay: {
                delay: 3500,
                disableOnInteraction: false,
            },
            breakpoints: {
                480: {
                    slidesPerView: 3,
                },
            },
        } );
    }


}


export const gallery = new Gallery();