import "@lottiefiles/lottie-player";

export default class Lottie {

    constructor( element ) {
        this.element = element;

        this.ecoswitches = document.querySelectorAll( '[data-ecoswitch]' );

        this.init();
        this.addEventListeners();
    }

    init() {

        if (localStorage.getItem('ecoswitch') == 'reg') {
            this.element.src = this.element.getAttribute('data-src-reg');
            this.element.load(this.element.getAttribute('data-src-reg'));
        } else if (localStorage.getItem('ecoswitch') == 'eco' || localStorage.getItem('ecoswitch') == null) {
            this.element.src = this.element.getAttribute('data-src-eco');
            this.element.load(this.element.getAttribute('data-src-eco'));
        }
    }

    addEventListeners() {
        this.ecoswitches.forEach( ( eswitch ) => {
            eswitch.addEventListener( 'click', ( event ) => { this.init(); } )
        });
    }
}