import '../../js/components/fullpage.parallax.min.js';
import fullpage from '../../js/components/fullpage.extensions.min.js';

export default class Fullpage {

    constructor( element ) {
        this.element = element;
        this.initFullpage();
    }

    initFullpage() {


        new fullpage(this.element, {
            licenseKey: 'CLK3I-WZPDJ-647KK-HH097-SMYQN',
            parallaxEffect: true,
            parallaxKey: 'TXJjRzl6ZEcxaGEyOWxkMlZwWkdWdUxtTnZiUT09aDBfNzAyY0dGeVlXeHNZWGc9aDh0',
            scrollingSpeed: 400,
            slidesNavigation: false,
            scrollOverflow: true,
            // scrollBar: true,
            loopHorizontal: false,
            parallax: true,
            parallaxOptions: { 
                type: 'reveal', 
                percentage: 62, 
                property: 'translate'
            }
        });

    }


}