import Fullpage from './components/fullpage';
import Menu from './components/menu';
import { gallery } from './components/block-slider';
import Lottie from './components/lottie';


const fullpageElement = document.querySelector('[data-full-page]');
const menuElement = document.querySelector('[data-hamburger]');
const sliderBlockElement = document.querySelector( '[data-block-slider]' );
const lottieElements = document.querySelectorAll( '[data-lottie]' );

if ( fullpageElement ) {
    const fullpage = new Fullpage (fullpageElement);
}

if ( menuElement ) {
    const menu = new Menu (menuElement);
}

if ( sliderBlockElement ) {
    gallery.init( sliderBlockElement );
}

if ( lottieElements ) {
    lottieElements.forEach(element => {
        const lottie = new Lottie( element );
    });
}